const Application = {
  ORG: 'AirTicketsService',
  ORG_DISPLAY_NAME: 'AirTicketsService',
  ORG_ADD_LINE1: '',
  ORG_ADD_LINE2: '',
  STORAGE_HOST: 'https://storage.googleapis.com',
  PRODUCTION_API_BASE_URL: 'https://myfaresinfo.com/alpha',
  LOCAL_API_BASE_URL: 'http://localhost:8080/alpha',
  STORAGE_URL_STATIC_ASSETS_PATH: 'https://storage.googleapis.com/sfc-static',
  DOMAIN: 'airticketsservice.com',
  SUPPORT_PHONE: '+1 (469) 795-7875',
  CONTACT_EMAIL: 'airticketservicesteam@gmail.com',
  MAP_ADDRESS: '',
  CALL_NOW_POPUP_HEADING_TEXT: 'Call now for Booking, Changes and Cancellation',
  CALL_NOW_POPUP_SAVE_PRICE: 30,
  CALL_NOW_POPUP_SAME_PAGE_TIMEOUT_SEC: 40,
  DISCLAIMER: `Looking for a comprehensive travel experience at budget-friendly rates? AirTicketsService is the quickest and the easiest way to book flights online at discounted rates. Since the time we came into inception, we lead the entire market of flight booking. AirTicketsService offers you the best control over the travel plans.`,
  GA_ID: '',
};
export default Application;
